import { Inject, Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { environment } from 'src/environments/environment';
import { staticApiResponseAction } from '../api/api.actions';
import { environmentLoadedFailure, environmentLoadedSuccess } from './environment.actions';
import { EnvironmentWithDeviceInfo } from './environment.models';
import { getDeviceInfo } from './environment.utils';

const { appVersion } = environment;
const IS_PROD: boolean = !!parseInt(`${appVersion}`);
const NON_PROD_ENV_NAME = 'development';
const PRODUCTION_ENV_NAME = 'production';

let releaseStage = IS_PROD ? PRODUCTION_ENV_NAME : NON_PROD_ENV_NAME;

Bugsnag.start({
  apiKey: 'f5f3a57da0371ce0225fd4924c33b1ed',
  appVersion,
  appType: 'client',
  maxBreadcrumbs: 100,
  maxEvents: 100,
  redactedKeys: ['access_token'],
  releaseStage,
  enabledReleaseStages: [PRODUCTION_ENV_NAME],
  onError(event) {
    /**
     * Our environment release stage is determined asyncronously. This doesn't stop it from reporting on page load,
     * but if the environment comes back that it's not production, it will after that.
     * @see {@link: https://github.com/bugsnag/bugsnag-js/issues/813}
     */
    return releaseStage === PRODUCTION_ENV_NAME;
  },
  metadata: {
    ClientConfig: {
      referrer: document?.referrer,
    },
  },
});

/**
 * Handles sending and receiving the environment data so we know where to send API requests.
 */
@Injectable()
export class EnvironmentEffects {
  /**
   * Our constructor
   * @param actions$ The stream of actions
   * @param window The window object
   * @param deviceService Device service
   */
  constructor(private actions$: Actions, @Inject(WINDOW) private window: Window, private deviceService: DeviceDetectorService) {}

  /**
   * Sends the request to our PHP file to grab the environment information.
   */
  loadEnvironment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(staticApiResponseAction),
      filter(({ requestType }) => requestType === ApiRequestType.GET_ENVIRONMENT),
      map(({ response }) => {
        const payload: EnvironmentWithDeviceInfo = {
          ...response,
          gatewayUrl: new URL(response.gatewayUrl).origin,
          ipAddress: '',
          device: getDeviceInfo(this.deviceService.getDeviceInfo(), this.window),
          build: appVersion,
        };

        /**
         * If we're not in production, stop bugsnag from reporting
         * @see {@link: https://github.com/bugsnag/bugsnag-js/issues/813}
         */
        if (!payload.isProduction) {
          releaseStage = NON_PROD_ENV_NAME;
        }

        return environmentLoadedSuccess({ payload });
      }),
      catchError(() => of(environmentLoadedFailure()))
    )
  );
}
