import { PaymentProviderType } from './../../../core/payment-configuration/payment-configuration.model';
import { InitiateAltPayResponse } from 'src/app/core/api/responses/initiate-alt-pay';
import { UserInfo } from 'src/app/core/application-bridge/application-bridge.models';
import { PaymentMethodCode } from 'src/app/core/payment-configuration/payment-configuration.model';
import { validObject, validString } from 'src/app/shared/utilities/types.utils';

export enum AlternatePaymentQueryParams {
  AUTH_ID = 'acsop_aid',
  LANGUAGE = 'acsop_ln',
  REDIRECT_URL = 'acsop_ul',
  PAYMENT_MERCHANT_ID = 'acsop_pid',
  PAYMENT_TYPE = 'acsop_pt',
  TENANT = 'acsop_tn',
  SESSION_TOKEN = 'acsop_t',
  SESSION_ID = 'acsop_i',
}

export interface AlternatePaymentRedirectParams {
  MD: string;
  PaReq: string;
  TermUrl: string;
}

export interface AlternatePaymentRedirectPayload {
  amount?: number;
  authId: number;
  method: string;
  paymentReference: string;
  url: string;
  paymentMerchantId: number;
  paymentMethodCode: string;
  paymentProviderName: string;
  rawCardBrand: string;
  redirectParams?: AlternatePaymentRedirectParams;
}

export function hasValidRedirectParams(redirectParams: AlternatePaymentRedirectParams): boolean {
  return Object.values(redirectParams).every(validString);
}

/**
 * Given the demographic information return a new object with the user information and the correct keys for billing payload
 * @param demographics demographics object from store
 */
export function buildBillingPayload(demographics: Partial<DemographicsFormData>): UserInfo {
  return {
    firstName: demographics?.firstName ?? '',
    lastName: demographics?.lastName ?? '',
    address1: demographics?.address1 ?? '',
    address2: demographics?.address2 ?? '',
    city: demographics?.city ?? '',
    country: demographics?.country ?? '',
    email: demographics?.email ?? '',
    middleInitial: demographics?.middleInitial ?? '',
    phone: demographics?.phone ?? '',
    phoneCountryCode: demographics?.telCode ?? '',
    stateProvince: demographics?.state ?? '',
    zipPostal: demographics?.zip ?? '',
  };
}

/**
 * Given the demographic information return a new object with the user information and the correct keys for delivery payload
 * @param deliveryAddress demographic delivery object from store
 */
export function buildDeliveryPayload(deliveryAddress: Partial<DemographicsFormData>): UserInfo {
  return {
    firstName: deliveryAddress?.firstName ?? '',
    lastName: deliveryAddress?.lastName ?? '',
    middleInitial: deliveryAddress?.middleInitial ?? '',
    address1: deliveryAddress?.address1 ?? '',
    address2: deliveryAddress?.address2 ?? '',
    city: deliveryAddress?.city ?? '',
    country: deliveryAddress?.country ?? '',
    stateProvince: deliveryAddress?.state ?? '',
    zipPostal: deliveryAddress?.zip ?? '',
    email: deliveryAddress?.email ?? '',
    phone: deliveryAddress?.phone ?? '',
  };
}

/**
 * Check Initial Alt Pay response for QR data property and is a valid string
 * @param response InitialAltPay response
 */
export function hasValidQrData(response: InitiateAltPayResponse): boolean {
  const { qr_code_data, action } = response;
  const hasValidAdyenData = validObject(action) && ['paymentData', 'paymentMethodType', 'qrCodeData', 'type'].every((key) => validString(action[key]));

  return hasValidAdyenData || validString(qr_code_data);
}

/**
 * Check if Adyen's web component is required to render QR codes
 * @param provider PaymentProviderType
 * @param paymentMethodCode PaymentMethodCode
 */
export function isAdyenWebComponentRequired(provider: PaymentProviderType, paymentMethodCode: PaymentMethodCode): boolean {
  return provider === PaymentProviderType.Adyen && [PaymentMethodCode.BANCONTACT_MOBILE, PaymentMethodCode.WECHAT_PAY].includes(paymentMethodCode);
}

/**
 * Check if a custom QR component is required to render QR data
 * @param paymentMethodCode PaymentMethodCode
 */
export function isCustomQrComponentRequired(paymentMethodCode: PaymentMethodCode): boolean {
  return paymentMethodCode === PaymentMethodCode.TTB_QR_CODE;
}

/**
 * Check if payment method redirection is required
 * @param paymentMethodCode PaymentMethodCode
 */
export function isPendingWithoutRedirect(paymentMethodCode: any): boolean {
  return [PaymentMethodCode.MB_WAY, PaymentMethodCode.SEPADD, PaymentMethodCode.TTB_QR_CODE].includes(paymentMethodCode);
}
