import { Inject, Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { Store, createSelector, select } from '@ngrx/store';
import { get } from 'lodash-es';
import { AcLoggerService } from 'src/app/core/logger/logger.service';
import { DOCUMENT } from 'src/app/core/injection-token/document/document';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { Observable, catchError, switchMap } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { ApiService } from 'src/app/core/api/api.service';
import { AmazonPayGenerateSessionRequest, AmazonPayGenerateSessionResponse } from 'src/app/core/api/responses/generate-amazon-pay-session';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { AmazonPayApplicationConfig } from 'src/app/core/application-config/application-config.state';
import { selectLanguage } from 'src/app/core/client-configuration/client-configuration.selectors';
import { selectIsProduction } from 'src/app/core/environment/environment.selectors';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { selectParentConfig, selectParentCurrencyCode } from 'src/app/core/parent-config/parent-config.selectors';
import { ParentConfigState } from 'src/app/core/parent-config/parent-config.state';
import { getLineItemsAndFees } from 'src/app/core/parent-config/parent-config.utils';
import { PaymentMethodCode } from 'src/app/core/payment-configuration/payment-configuration.model';
import { selectAlternatePayments } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { APP_CONFIG_AMAZON_PAY } from 'src/app/shared/enums/application-config.enum';
import { selectPaymentAmount } from 'src/app/shared/selectors/configuration.selectors';
import { getPaymentMerchantIdByPaymentMethodCode } from 'src/app/shared/utilities/alternate-payment.utils';
import { calculateSplitAmountWithDonation } from 'src/app/shared/utilities/donation.utils';
import { calculateSplitAmountWithInsurance } from 'src/app/shared/utilities/insurance.utils';
import { toObservable } from 'src/app/shared/utilities/observable.utils';
import { addScript } from 'src/app/shared/utilities/script-loader.utils';
import { validObject, validString } from 'src/app/shared/utilities/types.utils';
import { openPopupWindow } from 'src/app/shared/utilities/window.utils';
import { selectDonation, selectTicketProtection } from '../../extras/extras.selectors';
import { DonationState, TicketProtectionState } from '../../extras/extras.state';
import { selectBillingState } from '../billing.selectors';
import { updateAmazonPayAvailabilityAction } from './../billing.actions';
import {
  cancelAmazonPaySessionAction,
  completeAmazonPaySessionAction,
  initializeAmazonPayAction,
  loadAmazonPaySessionAction,
  openAmazonPayWindowAction,
} from './amazon-pay.actions';
import { CHECKOUT, PAY_ONLY } from './amazon-pay.enums';
import { getAmazonPayCheckoutLanguage, isValidAmazonPayCurrency, isValidAmazonPayRegion } from './amazon-pay.utils';

const EVENT_AMAZON_PAY_COMPLETE = 'accessoPay::amazonPayComplete';
const EVENT_AMAZON_PAY_CANCEL = 'accessoPay::amazonPayCancel';

@Injectable({
  providedIn: 'root',
})
export class AmazonPayService {
  /**
   * Amazon Pay SDK API
   */
  private amazon: AP_AmazonPaySDK;
  /**
   * Amazon Pay app config
   */
  private amazonPayConfigs: AmazonPayApplicationConfig;
  /**
   * Amazon Pay window
   */
  amazonPayWindow: Window;
  /**
   * Payment amount for cart
   */
  private paymentAmount: number = 0;
  /**
   * Payment amount with donation
   */
  private totalPaymentAmount: number = 0;
  /**
   * Payment Merchant Id
   */
  private paymentMerchantId: number;
  /**
   * Parent Configureation State
   */
  private parentConfig: ParentConfigState;
  /**
   * Donation State
   */
  private donation: DonationState;
  /**
   * Amazon Pay enabled
   */
  private isAmazonPayEnabled: boolean = false;
  /**
   * Parent Config: Currency
   */
  private currency: string;
  /**
   * Client Config: Language
   */
  private language: string;
  /**
   * Environment State: isProduction
   */
  private isDevEnv: boolean = true;
  /**
   * Amazon Pay session details
   */
  private currentSession: AmazonPayGenerateSessionResponse;
  /**
   * User completed Amazon Pay session
   */
  private isAmazonPaySessionComplete: boolean;
  /**
   * Ticket insurance state
   */
  private insurance: TicketProtectionState;
  /**
   * The auth id for the current transaction
   */
  private paymentReference: string;

  /**
   * Constructor
   * @param window window
   * @param document document
   * @param store our store
   * @param apiService ApiService
   * @param logger AcLoggerService
   * @param localeService AcLoggerService
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private store: Store<AppState>,
    private api: ApiService,
    private logger: AcLoggerService,
    private localeService: LocaleService
  ) {
    this.store
      .pipe(
        select(
          createSelector(
            selectAppConfig([APP_CONFIG_AMAZON_PAY]),
            selectLanguage,
            selectParentCurrencyCode,
            selectBillingState,
            selectParentConfig,
            selectAlternatePayments,
            selectPaymentAmount,
            selectDonation,
            selectTicketProtection,
            (appConfig, language, currency, billingState, parentConfig, altPayments, amount, donation, ticketProtection) => ({
              appConfig,
              language,
              currency,
              billingState,
              parentConfig,
              altPayments,
              amount,
              donation,
              ticketProtection,
            })
          )
        )
      )
      .subscribe(({ appConfig, language, currency, billingState, parentConfig, altPayments, amount, donation, ticketProtection }) => {
        this.language = language;
        this.currency = currency;
        this.paymentAmount = amount;
        this.donation = donation;
        this.totalPaymentAmount = amount + donation.amount;
        this.amazonPayConfigs = appConfig[APP_CONFIG_AMAZON_PAY];
        this.parentConfig = parentConfig;
        this.isAmazonPayEnabled = billingState.amazonpay && validObject(appConfig[APP_CONFIG_AMAZON_PAY]);
        this.insurance = ticketProtection;
        if (!ticketProtection.splitInsurance) {
          this.totalPaymentAmount += ticketProtection.quoteAmount;
        }

        if (this.isAmazonPayEnabled && altPayments?.length) {
          this.paymentMerchantId = getPaymentMerchantIdByPaymentMethodCode(altPayments, PaymentMethodCode.AMAZON_PAY);
        }
      });

    this.store.pipe(select(selectIsProduction)).subscribe((isProd) => {
      this.isDevEnv = !isProd;
    });
  }

  /**
   * Amazon Pay enabled
   */
  get isEnabled(): boolean {
    return this.isAmazonPayEnabled;
  }

  /**
   * Payment Amount from Amazon Pay current session
   */
  get sessionAmount(): number {
    return parseFloat(JSON.parse(this.sessionInfo?.payload || '{}').paymentDetails?.chargeAmount.amount ?? 0);
  }

  /**
   * Payment total for Amazon Pay checkout
   */
  get totalAmount(): number {
    return this.sessionAmount || this.totalPaymentAmount;
  }

  /**
   * Get Amazon Pay current sessions
   */
  get sessionInfo(): AmazonPayGenerateSessionResponse {
    return this.currentSession;
  }

  /**
   * Set Amazon Pay session
   */
  set sessionInfo(details: AmazonPayGenerateSessionResponse) {
    this.currentSession = details;
  }

  /**
   * Require a new Amazon Pay session
   */
  private get isNewSessionRequired(): boolean {
    return !this.sessionInfo || this.sessionAmount !== this.totalPaymentAmount;
  }

  /**
   * Amazon Pay ledger currency
   */
  private get ledgerCurrency(): AmazonPaySDKLedgerCurrency {
    const sessionCurrency: string = JSON.parse(this.sessionInfo?.payload || '{}').paymentDetails?.chargeAmount.currencyCode;

    if (isValidAmazonPayCurrency(sessionCurrency)) {
      return sessionCurrency as AmazonPaySDKLedgerCurrency;
    }

    if (isValidAmazonPayCurrency(this.currency)) {
      return this.currency as AmazonPaySDKLedgerCurrency;
    }

    return null;
  }

  /**
   * Amazon Pay checkout language
   */
  private get checkoutLanguage(): AmazonPaySDKCheckoutLanguage {
    return getAmazonPayCheckoutLanguage(this.amazonPayConfigs, this.language);
  }

  /**
   * Validate and build script from configs
   */
  private getAmazonPayScript(): string {
    const region = this.amazonPayConfigs?.region || '';
    if (isValidAmazonPayRegion(region)) {
      return `https://static-${region.toLocaleLowerCase()}.payments-amazon.com/checkout.js`;
    }
    return '';
  }

  /**
   * Sets payment reference created from create order response
   * @param authId auth id
   */
  setPaymentReference(paymentReference: string): void {
    if (validString(paymentReference)) {
      this.paymentReference = paymentReference;
    }
  }

  /**
   * Returns payment reference from create order request
   * @returns auth id for current transaction
   */
  getPaymentReference(): string {
    return this.paymentReference;
  }

  /**
   * Disable Amazon Pay
   */
  disable(): void {
    this.store.dispatch(updateAmazonPayAvailabilityAction({ status: false }));
  }

  /**
   * Load the Amazon Pay SDK script
   */
  loadScript(): Observable<boolean> {
    if (this.isAmazonPayEnabled) {
      return addScript(this.getAmazonPayScript(), false, { id: 'ap-amazonpay' }).pipe(
        switchMap((_) => {
          this.amazon = this.getInstance();
          const isScriptLoaded = validObject(this.amazon);
          if (!isScriptLoaded) {
            this.disable();
          }
          return toObservable(isScriptLoaded);
        }),
        catchError((_) => {
          this.disable();
          return toObservable(false);
        })
      );
    } else {
      this.disable();
      return toObservable(false);
    }
  }

  /**
   * Fetch the Amazon Pay SDK instance from DOM
   */
  getInstance(): AP_AmazonPaySDK {
    return get(this.window, 'amazon', null) as AP_AmazonPaySDK;
  }

  /**
   * Close Amazon Pay window
   */
  closeWindow(): void {
    this.amazonPayWindow?.close();
  }

  /**
   * Loads session data to Amazon Pay window
   */
  loadDataToAmazonPayWindow(): void {
    this.amazonPayWindow.document.write(this.generatePopupPageHTML(this.sessionInfo));
  }

  /**
   * Returns the request object to create amazon pay order for Payment Service
   */
  private getAmazonPaySessionRequest(): AmazonPayGenerateSessionRequest {
    const { cartItems, fees } = getLineItemsAndFees(this.parentConfig);
    let totalAmount = this.paymentAmount;
    const { insurance, amount: amountAfterInsurance } = calculateSplitAmountWithInsurance(totalAmount, this.insurance);
    totalAmount = amountAfterInsurance;
    const { donation, amount } = calculateSplitAmountWithDonation(totalAmount, this.donation);
    const reqObj: AmazonPayGenerateSessionRequest = {
      merchant_id: this.paymentMerchantId,
      amount,
      cartItems,
      fees,
      recurring_flag: this.parentConfig?.config?.recurring ? 1 : 0,
      return_url: `https://${this.window.location.host}/php/result/amazon-pay`,
      no_shipping_flag: 1,
      ...(donation && { donation }),
      ...(insurance && { insurance }),
    };

    return reqObj;
  }

  /**
   * Generate html for amazon pay in new window
   * @param sessionDetails required details to initiate amazon payment
   */
  private generatePopupPageHTML(sessionDetails: AmazonPayGenerateSessionResponse): string {
    const { payload, signature } = sessionDetails;
    const buttonColor = this.amazonPayConfigs.buttonColor;
    return `<html>
          <body>
            <div id="AmazonPayButton"></div>
            <div class="container">
              Redirecting to Amazon Pay
            </div>
            <style>
              .container {
                background-color: #FFF;
                color: #000;
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            </style>
            <script>
              (function() {
                var script = document.createElement('script');
                script.onload = checkAndInitializeAmazon;
                script.src = '${this.getAmazonPayScript()}';
                document.body.appendChild(script);
                function checkAndInitializeAmazon() {
                  if (amazon) {
                    var amazonPayButton = amazon.Pay.renderButton('#AmazonPayButton', {
                      merchantId: '${this.amazonPayConfigs.merchantId}',
                      publicKeyId: '${this.amazonPayConfigs.publicKeyId}',
                      ledgerCurrency: '${this.ledgerCurrency}',
                      checkoutLanguage: '${this.checkoutLanguage}',
                      productType: '${PAY_ONLY}',
                      placement: '${CHECKOUT}',
                      buttonColor: '${buttonColor}',
                      sandbox: ${this.isDevEnv},
                    });
                    amazonPayButton.initCheckout({
                      estimatedOrderAmount: { amount: ${this.totalAmount}, currencyCode: '${this.ledgerCurrency}' },
                      createCheckoutSessionConfig: {
                        payloadJSON: '${payload}',
                        signature: '${signature}',
                        publicKeyId: '${this.amazonPayConfigs.publicKeyId}',
                      },
                    });
                  } else {
                    setTimeout(checkAndInitializeAmazon, 100);
                  }
                }
              }());
            </script>
          </body>
        </html>
      `;
  }

  /**
   * Opens a new window for amazon pay payment session
   */
  openAmazonPayWindow(): void {
    Bugsnag.leaveBreadcrumb('Initiating Amazon Pay session in new window');
    this.amazonPayWindow = openPopupWindow(this.window, {
      url: `${this.window.origin}/sdk/redirect.html`, // prevent CORS issue in iOS/Safari
      windowName: 'Amazon Pay',
      width: 720, // some arbitrary width.
      height: 720, // some arbitrary height.
      windowFeatures: ['allow-top-navigation', 'allow-top-navigation-by-user-activation'],
    });

    if (this.amazonPayWindow) {
      this.isAmazonPaySessionComplete = false; // Need to reset if payment failed
      this.startMessageListener();
      this.onAmazonPayWindowClosed();
      // Checking if we can reuse session data. Should avoid creating multiple for same transaction
      if (this.isNewSessionRequired) {
        this.store.dispatch(initializeAmazonPayAction({ payload: this.getAmazonPaySessionRequest() }));
      } else {
        this.store.dispatch(loadAmazonPaySessionAction());
      }
    } else {
      Bugsnag.notify(new Error(`Failed to open Amazon Pay window`));
    }
  }

  /**
   * Mounts amazon pay button to given element id
   * @param elementId id to mounts amazon pay button
   */
  renderAmazonPayBtn(elementId: string): void {
    const ledgerCurrency = this.ledgerCurrency;
    const checkoutLanguage = this.checkoutLanguage;
    const amazonPayButton = this.amazon.Pay.renderButton(`#${elementId}`, {
      merchantId: this.amazonPayConfigs.merchantId,
      publicKeyId: this.amazonPayConfigs.publicKeyId,
      ledgerCurrency,
      checkoutLanguage,
      productType: PAY_ONLY,
      placement: CHECKOUT,
      buttonColor: this.amazonPayConfigs.buttonColor || ('gold' as AmazonPaySDKBtnColor),
      sandbox: this.isDevEnv,
      design: 'C0001',
    });

    amazonPayButton.onClick(() => this.store.dispatch(openAmazonPayWindowAction()));
    this.logger.log(
      `Region: ${this.amazonPayConfigs.region} ConfigLocale: ${this.amazonPayConfigs.locale} ApayLocale: ${this.language} AmazonPayLanguage: ${checkoutLanguage}`
    );
  }

  /**
   * Handles response from amazon pay session
   * @param event message event
   */
  private handleAmazonPaySessionResponse = (event: Event): void => {
    const data = (event as any)?.data;
    if (data?.type === EVENT_AMAZON_PAY_CANCEL) {
      this.closeWindow();
    }
    if (data?.type === EVENT_AMAZON_PAY_COMPLETE) {
      this.isAmazonPaySessionComplete = true;
      this.amazonPayWindow.close();
      this.store.dispatch(
        completeAmazonPaySessionAction({
          payload: {
            authId: this.currentSession.auth_id,
            amount: this.totalPaymentAmount,
            merchantId: this.paymentMerchantId,
            checkoutSessionId: data?.payload,
            paymentReference: this.getPaymentReference(),
          },
        })
      );
    }
  };

  /**
   * Handles Amazon Pay events when amazon pay window closes
   */
  private onAmazonPayWindowClosed(): void {
    const checkWindowClosed = () => {
      if (!this.amazonPayWindow.closed) {
        return setTimeout(checkWindowClosed, 500);
      } else {
        this.stopMessageListener();
        if (!this.isAmazonPaySessionComplete) {
          this.store.dispatch(cancelAmazonPaySessionAction());
        }
      }
    };

    setTimeout(checkWindowClosed, 500);
  }

  /**
   * Adds event listner for amazon pay event
   */
  private startMessageListener(): void {
    this.window.addEventListener('message', this.handleAmazonPaySessionResponse);
  }

  /**
   * Removes event listner for amazon pay event
   */
  private stopMessageListener(): void {
    this.window.removeEventListener('message', this.handleAmazonPaySessionResponse);
  }
}
