import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Payment, PaymentState, TescoPaymentMethod } from './payment.state';
import { PaymentMethodCode, PaymentProviderType } from 'src/app/core/payment-configuration/payment-configuration.model';
import { selectBaseRequest } from 'src/app/shared/selectors/api.selectors';
import { PaymentAuthRequest } from 'src/app/core/api/endpoints/payment-auth';

export const selectPaymentState = createFeatureSelector<PaymentState>('payment');

export const selectTescoVouchers = createSelector(selectPaymentState, (state: PaymentState) => {
  return state.payments.filter(
    (p): p is Payment<PaymentProviderType.Tesco, TescoPaymentMethod> =>
      p.provider === PaymentProviderType.Tesco && p.paymentMethod.paymentMethodCode === PaymentMethodCode.TESCO
  );
});

export const selectTescoVoucherBalance = createSelector(selectTescoVouchers, (vouchers) => {
  return vouchers.reduce((acc, voucher) => {
    return acc + voucher.amount;
  }, 0);
});

export const selectPaymentBalance = createSelector(selectPaymentState, (state: PaymentState) => {
  return state.payments.reduce((acc, payment) => {
    return acc + payment.amount;
  }, 0);
});

export const selectPaymentMethods = createSelector(selectPaymentState, (state: PaymentState) => {
  return state.payments;
});

export const selectPaymentAuthRequest = createSelector(
  selectPaymentMethods,
  selectBaseRequest,
  selectPaymentBalance,
  (payments, baseRequest, balance): PaymentAuthRequest => {
    // ************ REMOVE ************ //
    // remove after all payment methods are implemented to use consolidated API
    baseRequest.amount.cartTotal = balance;
    // ************ REMOVE ************ //

    return {
      ...baseRequest,
      payments,
    };
  }
);
