import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Params } from '@angular/router';
import { AppState } from 'src/app/app.state';
import { selectEnvironment } from 'src/app/core/environment/environment.selectors';
import { selectParentConfig, selectParentConfigAmount } from 'src/app/core/parent-config/parent-config.selectors';
import { selectConfiguredPayments, selectCreditCards } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { selectRequireCVV } from 'src/app/shared/selectors/configuration.selectors';
import { selectDelivery, selectDeliveryAmount } from '../delivery/delivery.selectors';
import { DeliveryState } from '../delivery/delivery.state';
import { CountriesProperties } from '../dynamic-form/utilities/countries-props.models';
import { selectCartAddonTotal, selectExtras, selectTicketInsuranceQuoteToken, selectTicketProtection } from '../extras/extras.selectors';
import { BillingState } from './billing.state';
import { selectStoredWallet } from './stored-wallet/stored-wallet.selectors';
import { selectGiftCardState } from '../gift-card/gift-card.selectors';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { APP_CONFIG_SANDBOX_MODE } from 'src/app/shared/enums/application-config.enum';
import { toDecimal } from 'src/app/shared/utilities/number.utils';

/**
 * Selects the main billing state
 * @param appState The global state
 */
export const selectBillingState = createFeatureSelector<BillingState>('billing');

/**
 * This is the sum of everything before any payments are processed.
 * Potential replacement for selectTotalAmount.
 */
export const selectCartTotal = createSelector(
  selectParentConfigAmount,
  selectDeliveryAmount,
  selectCartAddonTotal,
  (parentConfigAmount, deliveryAmount, cartAddonTotal): number => {
    return toDecimal(parentConfigAmount + deliveryAmount + cartAddonTotal, 2);
  }
);

/**
 * Payment amount with cart add-ons
 */
export const selectPaymentAmountWithExtras = createSelector(selectCartTotal, selectGiftCardState, selectExtras, (total, giftCardState, extrasState): number => {
  if (!giftCardState.isGiftCardOnlyPayment && giftCardState.totalBalance > 0) {
    return toDecimal(total - giftCardState.totalBalance, 2);
  }
  return total;
});

/**
 * Selects the main information about billing.
 */
export const selectBillingInformation = createSelector(
  selectRequireCVV,
  selectCreditCards,
  selectParentConfig,
  selectConfiguredPayments,
  selectEnvironment,
  selectStoredWallet,
  selectBillingState,
  selectTicketInsuranceQuoteToken,
  (requireCvv, creditCards, parentConfig, configuredPayments, environment, storedWallet, billingState, quoteToken) => {
    return {
      requireCvv,
      creditCards,
      parentConfig,
      configuredPayments,
      environment,
      storedWallet,
      billingState,
      quoteToken,
    };
  }
);

/**
 * Selects the state that contain billing demographics
 * @returns billing demographics
 */
export const selectBillingDemographics = createSelector(selectBillingState, (state: BillingState): Partial<DemographicsFormData> => state.demographics);

/**
 * Selects the state that contains redirect payment query params
 * @returns redirect payment query params
 */
export const selectRedirectPaymentsQueryParams = createSelector(selectBillingState, (state: BillingState): Params => state.redirectPaymentsQueryParams);

/**
 * Selects the state that contains gift card payment availability status
 * @returns availability status for gift card
 */
export const selectGiftCardConfigurationStatus = createSelector(selectBillingState, (state: BillingState): boolean => state.giftcard);

/**
 * Select the billing details required to complete the payment
 * @returns billing and delivery details
 */
export const selectBillingDetailsForPayment = createSelector(
  selectBillingState,
  selectDelivery,
  (billingState, deliveryState): { billingState: BillingState; deliveryState: DeliveryState } => {
    return { billingState, deliveryState };
  }
);

/**
 * Select ticketInsurance state and giftcard to render the Ewallets methods
 * @returns billing and delivery details
 */
export const selectShowEwalletPaymentMethods = createSelector(selectTicketProtection, selectGiftCardState, (ticketProtection, giftCardConfig): boolean => {
  return !(ticketProtection.isProtected && !ticketProtection.splitInsurance && giftCardConfig.totalBalance);
});

/**
 * Select the countries used billing details
 * @returns list of supported countries
 */
export const selectCountriesList = createSelector(selectBillingState, (state: BillingState): CountriesProperties[] => state?.formOptions?.countryCodes);

/**
 * Select Form Options used in Billing details
 * @param {string[]} names Properties to retrieve from Billing.formOptions
 * @returns Properties found
 */
export const selectFormOptions = <T extends string>(names: readonly T[]) => {
  return (state: AppState): Record<T, any> => {
    return names.reduce(
      (options: Record<T, any>, name: string) => {
        options[name] = state.billing?.formOptions[name];
        return options;
      },
      {} as Record<T, any>
    );
  };
};

/**
 * Select use sandbox flow
 */
export const selectUseSandbox = createSelector(selectEnvironment, selectAppConfig([APP_CONFIG_SANDBOX_MODE]), (environment, appConfigs): boolean => {
  return environment?.isProduction ? !!appConfigs[APP_CONFIG_SANDBOX_MODE] : true;
});
