import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createSelector, Store } from '@ngrx/store';
import {
  initiateAlternatePaymentRedirectAction,
  initiateAlternatePaymentsAction,
  confirmReadyForRedirectAction,
  initializeGiftCardSplitPaymentWithAlternatePaymentsAction,
  giftCardSplitPaymentWithAlternatePaymentsSuccessAction,
  prepareForAlternatePaymentRedirectAction,
  initiateBanContactCardAlternatePaymentAction,
} from './alternate-payments.actions';

import { AlternatePaymentsService, AltPayRedirectDetails } from './alternate-payments.service';
import { AppState } from 'src/app/app.state';
import { Inject, Injectable } from '@angular/core';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { sendMessageAction } from 'src/app/core/application-bridge/application-bridge.actions';
import { EVENT_PAYMENT_REDIRECT } from 'src/app/shared/enums/application-bridge.enums';
import { hidePageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { IS_IN_IFRAME } from 'src/app/shared/utilities/window.utils';
import { responseRequestType } from 'src/app/core/api/api.utilities';
import { AlternatePayment, PaymentMethodCode } from 'src/app/core/payment-configuration/payment-configuration.model';
import { selectGiftCard, selectPaymentConfiguration } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { getPaymentConfigurationByPaymentMethodCode } from 'src/app/shared/utilities/alternate-payment.utils';
import { selectGiftCardState } from '../../gift-card/gift-card.selectors';
import { hasPreAuthGiftCards } from '../../gift-card/gift-card.utils';
import { apiResponse, postApiRequest } from 'src/app/core/api/api.actions';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { CreateAccessoPaySessionResponse } from 'src/app/core/api/responses/create-accesso-pay-session';
import { PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { AlternatePaymentRedirectParams, AlternatePaymentRedirectPayload, hasValidRedirectParams } from './alternate-payments.utils';
import { selectPaymentAmount, selectTotalAmount } from 'src/app/shared/selectors/configuration.selectors';
import { DonationAuthRequest, InsuranceAuthRequest } from 'src/app/core/api/responses/authorize';
import { toDecimal } from 'src/app/shared/utilities/number.utils';
import { calculateSplitAmountWithDonation, convertDonationResponse } from 'src/app/shared/utilities/donation.utils';
import { selectDonation, selectTicketProtection } from '../../extras/extras.selectors';
import { calculateSplitAmountWithInsurance, convertInsuranceResponse } from 'src/app/shared/utilities/insurance.utils';

@Injectable()
export class AlternatePaymentsEffects {
  /**
   * The alternate payments configured
   */
  private alternatePayments: AlternatePayment[];

  /**
   * This effect is triggered when you want to initate an alternate payment.
   */
  initiateAlternatePayments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initiateAlternatePaymentsAction),
        map((action) =>
          this.altPayService.initiateAltPay({
            paymentType: action.paymentType,
            paymentMerchantId: action.paymentMerchantId,
            paymentMethodCode: action.paymentMethodCode,
            issuerId: action.issuerId,
          })
        )
      ),
    { dispatch: false }
  );

  /**
   * This effect is triggered when you want to initate BanContact Card alternate payment.
   */
  initiateBanContactCardAlternatePayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initiateBanContactCardAlternatePaymentAction),
        map((action) =>
          this.altPayService.initiateAltPay({
            encryptedToken: action.token,
            paymentType: PaymentType.BANCONTACT_DESKTOP,
            paymentMerchantId: action.paymentMerchantId,
            paymentMethodCode: PaymentMethodCode.BANCONTACT_DESKTOP,
          })
        )
      ),
    { dispatch: false }
  );

  /**
   * This effect is triggered when you want to initate an alternate payment.
   */
  prepareForRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(prepareForAlternatePaymentRedirectAction),
      withLatestFrom(
        this.store.select(createSelector(selectGiftCardState, selectTotalAmount, (giftCardState, totalAmount) => ({ giftCardState, totalAmount })))
      ),
      map(([action, { giftCardState, totalAmount }]) => {
        if (giftCardState.totalBalance > 0 && !hasPreAuthGiftCards(giftCardState.giftcards)) {
          return initializeGiftCardSplitPaymentWithAlternatePaymentsAction({ amount: totalAmount, payload: action.payload });
        } else {
          const { sessionRequest: body, paymentDetails } = action.payload;
          return postApiRequest({
            requestType: ApiRequestType.ACCESSO_PAY_SESSIONS,
            body,
            params: { module: 'alternatePayments' },
            callbackData: paymentDetails,
          });
        }
      })
    )
  );

  /**
   * Completes split payment
   */
  completeSplitPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(giftCardSplitPaymentWithAlternatePaymentsSuccessAction),
      withLatestFrom(this.store.select(selectGiftCardState)),
      map(([action, giftCardState]) => {
        const { sessionRequest: body, paymentDetails } = action.payload;
        const parsedBody = JSON.parse(body.payload);
        parsedBody.giftCardState = giftCardState;
        return postApiRequest({
          requestType: ApiRequestType.ACCESSO_PAY_SESSIONS,
          body: { ...body, payload: JSON.stringify(parsedBody) },
          params: { module: 'alternatePayments' },
          callbackData: paymentDetails,
        });
      })
    )
  );

  /**
   * This effect is triggered when a session token is created
   */
  onSessionCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(apiResponse),
        filter(responseRequestType(ApiRequestType.ACCESSO_PAY_SESSIONS)),
        filter((action) => action.params.module === 'alternatePayments'),
        map((action) => {
          const paymentDetails = action.callbackData as Partial<AltPayRedirectDetails>;
          const response = action.response as CreateAccessoPaySessionResponse;
          if (action.isOk) {
            this.altPayService.sendInitiateAltPay(response.redirectSessionKey, paymentDetails);
          } else {
            this.store.dispatch(
              showNotificationAction({
                buttonLabel: this.locales.get('common.close'),
                dialogType: NotificationDialogType.GENERAL,
                initiator: 'alternate payment service initiate alt pay',
                message: this.locales.get(`altpayerror.initiatefailure`),
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * This effect is triggered when you want to initiate an alternate payment redirect.
   * We need to show a message to the user that they're going to be redirect. Once they confirm,
   * we'll send them off.
   */
  initiateAlternatePaymentRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiateAlternatePaymentRedirectAction),
      map((action) => {
        const { url, method, paymentReference, authId, paymentMethodCode, amount, redirectParams, donation, insurance } = action;
        const { locales } = this;

        return showNotificationAction({
          initiator: 'confirm alternate payment redirect',
          dialogType: NotificationDialogType.GENERAL,
          buttonLabel: locales.get('common.confirm'),
          message: locales.get('alternatepayment.confirmredirect'),
          onClose: () => {
            this.onRedirectNotificationClosed(amount, authId, method, paymentReference, url, paymentMethodCode, redirectParams, donation, insurance);
          },
        });
      })
    )
  );

  /**
   * This effect is triggered once a user confirms that they will be redirected to make their
   * payment.
   */
  confirmReadyToRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(confirmReadyForRedirectAction),
        withLatestFrom(
          this.store.select(
            createSelector(
              selectGiftCardState,
              selectGiftCard,
              selectPaymentAmount,
              selectDonation,
              selectTicketProtection,
              (giftCardState, giftCardConfiguration, paymentAmount, donationsState, ticketProtection) => ({
                giftCardState,
                giftCardConfiguration,
                paymentAmount,
                donationsState,
                insurance: ticketProtection,
              })
            )
          )
        ),
        map(([action, { giftCardState, giftCardConfiguration, paymentAmount, donationsState, insurance: insuranceState }]) => {
          const { amount, url, method, paymentReference, authId, paymentMethodCode, redirectParams, donation, insurance } = action;
          const paymentConfiguration = getPaymentConfigurationByPaymentMethodCode(this.alternatePayments, paymentMethodCode);
          const { paymentMerchantId, rawCardBrand, paymentProviderName } = paymentConfiguration;
          let totalAmount = paymentAmount;
          const { amount: amountAfterInsurance } = calculateSplitAmountWithInsurance(amount, insuranceState);
          totalAmount = amountAfterInsurance;
          const { amount: totalSplitAmount } = calculateSplitAmountWithDonation(totalAmount, donationsState);

          if (!IS_IN_IFRAME) {
            const win = this.getWindow();
            win.location.href = url;
          } else {
            let payload;
            const redirectParamsValid = hasValidRedirectParams(redirectParams);
            if (giftCardState.totalBalance > 0) {
              const primaryPaymentPayload: AlternatePaymentRedirectPayload = {
                amount: donation ? totalSplitAmount : totalAmount,
                authId,
                method,
                paymentReference,
                url,
                paymentMerchantId,
                paymentMethodCode,
                paymentProviderName,
                rawCardBrand,
                ...(donation && { donation: convertDonationResponse(donation) }),
                ...(insurance && { insurance: convertInsuranceResponse({ ...insurance, insurance_amount: insurance.premium_amount }) }),
              };
              if (redirectParamsValid) {
                primaryPaymentPayload.redirectParams = redirectParams;
              }
              payload = [primaryPaymentPayload];
              const {
                paymentProviderName: gPaymentProviderName,
                paymentMerchantId: gPaymentMerchantId,
                paymentMethodCode: gPaymentMethodCode,
                rawCardBrand: gRawCardBrand,
              } = giftCardConfiguration;
              giftCardState.giftcards.forEach((card) => {
                payload.push({
                  amount:
                    card.donation || card.insurance
                      ? toDecimal(card.preAuthBalance - (card.donation?.donationAmount || 0) - (card.insurance?.premiumAmount || 0), 2)
                      : card.preAuthBalance,
                  authId: card.authId,
                  paymentReference: card.paymentReference,
                  paymentMerchantId: gPaymentMerchantId,
                  paymentMethodCode: gPaymentMethodCode,
                  paymentProviderName: gPaymentProviderName,
                  rawCardBrand: gRawCardBrand,
                  ...(card.donation && { donation: card.donation }),
                  ...(card.insurance && { insurance: card.insurance }),
                });
              });
            } else {
              payload = {
                amount: donation ? totalSplitAmount : totalAmount,
                authId,
                method,
                paymentReference,
                url,
                paymentMerchantId,
                paymentMethodCode,
                paymentProviderName,
                rawCardBrand,
                ...(donation && { donation: convertDonationResponse(donation) }),
                ...(insurance && { insurance: convertInsuranceResponse({ ...insurance, insurance_amount: insurance.premium_amount }) }),
              };
              if (redirectParamsValid) {
                payload.redirectParams = redirectParams;
              }
            }

            [
              hidePageSpinner({
                initiator: 'alternate payment redirect parent',
              }),
              sendMessageAction({
                key: EVENT_PAYMENT_REDIRECT,
                payload,
              }),
            ].forEach((newAction) => this.store.dispatch(newAction));
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * After a user has chosen what they want to happen when prompted that they'll get redirected, handle it.
   * @param authId The auth_id
   * @param method The method for sending them to the url
   * @param paymentReference The payment reference id
   * @param url The url to route them to
   * @param paymentMethodCode the payment method code for the payment type
   * @param redirectParams the redirect params
   */
  private onRedirectNotificationClosed(
    amount: number,
    authId: number,
    method: string,
    paymentReference: string,
    url: string,
    paymentMethodCode: PaymentMethodCode,
    redirectParams: AlternatePaymentRedirectParams,
    donation: DonationAuthRequest,
    insurance: InsuranceAuthRequest
  ): void {
    this.store.dispatch(
      confirmReadyForRedirectAction({
        amount,
        authId,
        method,
        paymentReference,
        url,
        paymentMethodCode,
        redirectParams,
        donation,
        insurance,
      })
    );
  }

  /**
   * Our constructor
   * @param actions$ The action stream.
   * @param store The store.
   * @param altPayService The alternate payment service
   * @param locales Our locale service
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    private actions$: Actions,
    private store: Store<AppState>,
    private altPayService: AlternatePaymentsService,
    private locales: LocaleService
  ) {
    store.select(selectPaymentConfiguration).subscribe((paymentConfig) => {
      this.alternatePayments = paymentConfig?.configuredPayments?.alternatePayments || [];
    });
  }

  /**
   * Returns the windows option.
   * @returns The window object
   */
  getWindow(): Window {
    return this.window;
  }
}
