import { Inject, Injectable } from '@angular/core';
import { Store, createSelector, select } from '@ngrx/store';
import { cloneDeep, isFunction } from 'lodash-es';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { sendPaymentComplete } from 'src/app/core/analytics/analytics.actions';
import { ApiStatus } from 'src/app/core/api/api.interface';
import { ApiService } from 'src/app/core/api/api.service';
import { DonationAuthRequest, InsuranceAuthResponse } from 'src/app/core/api/responses/authorize';
import { CreateAccessoPaySessionRequest } from 'src/app/core/api/responses/create-accesso-pay-session';
import { SessionState } from 'src/app/core/api/responses/get-accesso-pay-session';
import { InitiateAltPayRequest, InitiateAltPayResponse } from 'src/app/core/api/responses/initiate-alt-pay';
import { UpdateAltPayRequest, UpdateAltPayResponse } from 'src/app/core/api/responses/update-alt-pay';
import { sendMessageAction } from 'src/app/core/application-bridge/application-bridge.actions';
import {
  DonationAuthResponse,
  PaymentComplete,
  PaymentFailed,
  PaymentType,
  SplitPaymentComplete,
} from 'src/app/core/application-bridge/application-bridge.models';
import { selectClientConfiguration } from 'src/app/core/client-configuration/client-configuration.selectors';
import { ClientConfigurationState } from 'src/app/core/client-configuration/client-configuration.state';
import { DeviceType } from 'src/app/core/environment/environment.enums';
import { selectEnvironment } from 'src/app/core/environment/environment.selectors';
import { EnvironmentState } from 'src/app/core/environment/environment.state';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { DialogData, NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { hidePageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { selectParentConfig } from 'src/app/core/parent-config/parent-config.selectors';
import { ParentConfig, ParentConfigState } from 'src/app/core/parent-config/parent-config.state';
import { getLineItemsAndFees } from 'src/app/core/parent-config/parent-config.utils';
import { ConfiguredPayments, PaymentMethodCode, PaymentProviderType } from 'src/app/core/payment-configuration/payment-configuration.model';
import { selectConfiguredPayments, selectGiftCard } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { routeTo } from 'src/app/core/routing/routing.actions';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { EVENT_PAYMENT_FAILED } from 'src/app/shared/enums/application-bridge.enums';
import { selectPaymentAmount } from 'src/app/shared/selectors/configuration.selectors';
import { getPaymentProviderName } from 'src/app/shared/utilities/alternate-payment.utils';
import { buildBillinDemographics, formatDemographicsAsUserDataToCardHolder } from 'src/app/shared/utilities/demographics.utils';
import { calculateSplitAmountWithDonation, convertDonationResponse } from 'src/app/shared/utilities/donation.utils';
import { calculateSplitAmountWithInsurance, convertInsuranceResponse, formatInsuranceRequest } from 'src/app/shared/utilities/insurance.utils';
import { toDecimal } from 'src/app/shared/utilities/number.utils';
import { returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { validString } from 'src/app/shared/utilities/types.utils';
import { objectToQueryParams } from 'src/app/shared/utilities/url.utils';
import { selectDelivery } from '../../delivery/delivery.selectors';
import { DeliveryState } from '../../delivery/delivery.state';
import { selectTicketInsuranceQuoteToken, selectTicketProtection } from '../../extras/extras.selectors';
import { DonationState, TicketProtectionState } from '../../extras/extras.state';
import { selectGiftCardState } from '../../gift-card/gift-card.selectors';
import { GiftCardState } from '../../gift-card/gift-card.state';
import { initiateTmbThanachartAction } from '../../tmbthanachart/tmbthanachart.actions';
import { selectBillingDemographics } from '../billing.selectors';
import { selectDonation } from './../../extras/extras.selectors';
import { initiateAlternatePaymentRedirectAction, prepareForAlternatePaymentRedirectAction } from './alternate-payments.actions';
import {
  AlternatePaymentQueryParams,
  buildBillingPayload,
  buildDeliveryPayload,
  hasValidQrData,
  isAdyenWebComponentRequired,
  isCustomQrComponentRequired,
  isPendingWithoutRedirect,
} from './alternate-payments.utils';

export interface AltPayRedirectDetails {
  paymentMerchantId: number;
  paymentMethodCode: PaymentMethodCode;
  paymentType: string;
  issuerId: string;
  encryptedToken: string;
  paymentProvider?: PaymentProviderType;
  donation?: DonationAuthRequest;
}

export interface SessionPayload {
  parentConfig?: ParentConfig;
  giftCardState?: GiftCardState;
  queryParams?: string;
  redirectUrl?: string;
}

export interface buildPaymentCompletePayloadParams {
  InitAltPayResponse: InitiateAltPayResponse | UpdateAltPayResponse;
  paymentMethodCode: PaymentMethodCode;
  paymentType: PaymentType;
  paymentMerchantId: number;
  paymentProvider: string;
  amount?: number;
  deliveryStatus?: DeliveryState;
  demographics?: Partial<DemographicsFormData>;
  donation?: DonationAuthResponse;
  insurance?: InsuranceAuthResponse;
}

@Injectable({
  providedIn: 'root',
})
export class AlternatePaymentsService {
  /**
   * The amount for payment
   */
  private amount: number;
  /**
   * The redirect URL.
   */
  private redirectUrl: string;
  /**
   * The language
   */
  private language: string;
  /**
   * Our merchant id
   */
  private merchantId: string;
  /**
   * Our parent configuration
   */
  private parentConfig: ParentConfigState;
  /**
   * Our client configuration
   */
  private clientConfiguration: ClientConfigurationState;
  /**
   * Our delivery state
   */
  private delivery: DeliveryState;
  /**
   * Donation State
   */
  private donation: DonationState;
  /**
   * Initiate alt pay response
   */
  private initiateAltPayResponse: InitiateAltPayResponse;
  /**
   * The alternate payment billing demographics
   */
  private billingDemographics: Partial<DemographicsFormData>;
  /**
   * The quote token for ticket insurance
   */
  private ticketInsuranceQuoteToken: string;
  /**
   * The quote token for ticket insurance
   */
  private ticketInsuranceQuoteAmount: number;
  /**
   * The quote status for ticket insurance
   */
  private ticketInsuranceQuoteStatus: boolean;
  /**
   * The gift card start
   */
  private giftCardState: GiftCardState;
  /**
   * The environment information
   */
  private environmentInfo: EnvironmentState;
  /**
   * The configured payments
   */
  private configuredPayments: ConfiguredPayments;
  /**
   * The giftcard configs
   */
  private giftCardConfiguration;
  /**
   * The payment provider
   */
  private paymentProvider: PaymentProviderType = null;
  /**
   * Ticket insurance state
   */
  private insurance: TicketProtectionState;

  /**
   * Constructor
   * @param window The window object
   * @param store Store
   * @param apiService ApiService
   * @param localeService Locale Service
   */
  constructor(@Inject(WINDOW) private window: Window, private store: Store<AppState>, private apiService: ApiService, private localeService: LocaleService) {
    store
      .pipe(
        select(
          createSelector(
            selectParentConfig,
            selectDelivery,
            selectClientConfiguration,
            selectBillingDemographics,
            selectDonation,
            selectTicketInsuranceQuoteToken,
            selectPaymentAmount,
            selectGiftCardState,
            selectGiftCard,
            selectEnvironment,
            (
              parentConfig,
              deliveryState,
              clientConfiguration,
              demographics,
              donation,
              quoteToken,
              paymentAmount,
              giftCardState,
              giftCardConfiguration,
              environment
            ) => {
              const { language, merchantId, tenantName } = clientConfiguration;
              return {
                parentConfig,
                language,
                merchantId,
                tenantName,
                deliveryState,
                clientConfiguration,
                demographics,
                donation,
                quoteToken,
                paymentAmount,
                giftCardState,
                giftCardConfiguration,
                environment,
              };
            }
          )
        )
      )
      .subscribe(
        ({
          parentConfig,
          language,
          merchantId,
          tenantName,
          deliveryState,
          clientConfiguration,
          demographics,
          donation,
          quoteToken,
          paymentAmount,
          giftCardState,
          giftCardConfiguration,
          environment,
        }) => {
          const { config } = parentConfig;
          this.parentConfig = parentConfig;
          this.amount = paymentAmount;
          this.donation = donation;
          this.language = language;
          this.merchantId = merchantId;
          this.clientConfiguration = clientConfiguration;
          this.redirectUrl = validString(config.paymentRedirectUrl)
            ? config.paymentRedirectUrl
            : `https://${location.host}/${tenantName}/${merchantId}/${language}/redirect-result/`;
          this.billingDemographics = demographics;
          this.delivery = deliveryState;
          this.ticketInsuranceQuoteToken = quoteToken;
          this.giftCardState = giftCardState;
          this.giftCardConfiguration = giftCardConfiguration;
          this.environmentInfo = environment;
        }
      );
    // Needed to add another observable because of createSelector argument limits
    store
      .pipe(
        select(
          createSelector(selectConfiguredPayments, selectTicketProtection, (configuredPayments, ticketProtection) => ({
            configuredPayments,
            ticketProtection,
          }))
        )
      )
      .subscribe(({ configuredPayments, ticketProtection }) => {
        this.configuredPayments = configuredPayments;
        this.ticketInsuranceQuoteAmount = ticketProtection.quoteAmount;
        this.ticketInsuranceQuoteStatus = ticketProtection.isProtected;
        this.insurance = ticketProtection;
      });
  }

  /**
   * Returns Initiate alt pay response
   */
  getInitiateAltPayResponse(): InitiateAltPayResponse {
    return this.initiateAltPayResponse;
  }

  /**
   * Initiate Alternate Payment Flow
   * @param paymentDetails The alternate payment details
   */
  initiateAltPay(paymentDetails: Partial<AltPayRedirectDetails>): void {
    this.paymentProvider = getPaymentProviderName(this.configuredPayments, paymentDetails.paymentMethodCode);

    if (
      isAdyenWebComponentRequired(this.paymentProvider, paymentDetails.paymentMethodCode) &&
      [DeviceType.DESKTOP, DeviceType.TV].some((type) => type === this.environmentInfo.device.type)
    ) {
      this.sendInitiateAltPay('', paymentDetails);
    } else {
      this.prepareForRedirect(paymentDetails);
    }
  }

  /**
   * Update Alternate Payment status
   * @param authId auth id for current transaction
   * @param payload payment payload
   * @param paymentMerchantId payment merchant id
   * @param paymentType payment type
   */
  updateAltPay(authId: number, payload: string, paymentMerchantId: number, paymentMethodCode: PaymentMethodCode) {
    const { cartItems, fees } = getLineItemsAndFees(this.parentConfig);

    const updateAltPayRequest: UpdateAltPayRequest = {
      auth_id: authId,
      merchant_id: paymentMerchantId,
      payload,
      payment_type: paymentMethodCode,
      cartItems,
      fees,
    };

    if (validString(this.ticketInsuranceQuoteToken)) {
      updateAltPayRequest.insurance = formatInsuranceRequest(this.insurance);
    }

    return this.apiService.post<UpdateAltPayResponse>(ApiRequestType.UPDATE_ALT_PAY, updateAltPayRequest);
  }

  /**
   * Get the window object
   * @returns The window object
   */
  getWindow(): Window {
    return this.window;
  }

  /**
   * Gets the URL for a alternate payment to redirect to.
   * @returns The redirect url
   */
  private getRedirectUrl(redirectUrl: string, accessoPaySessionId: string, paymentMethodCode: PaymentMethodCode): string {
    const { language, merchantId, tenantName } = this.clientConfiguration;
    const { location } = this.getWindow();

    const isAdyenProvider = this.paymentProvider === PaymentProviderType.Adyen;
    const url = `https://${location.host}/php/result` + (isAdyenProvider ? '/adyen/' : '/altpayment/');
    const redirectTo = validString(redirectUrl) ? redirectUrl : `https://${location.host}/${tenantName}/${merchantId}/${language}/redirect-result/`;

    const paramsObj = {
      [AlternatePaymentQueryParams.SESSION_TOKEN]: accessoPaySessionId,
      [AlternatePaymentQueryParams.TENANT]: tenantName,
      paymentMethodCode,
    };

    if (isAdyenProvider) {
      // use query params for redirecting instead of using accessoPay sessions to retrieve redirect url
      paramsObj[AlternatePaymentQueryParams.SESSION_ID] = this.parentConfig.sessionId;
    }

    const queryParams = objectToQueryParams(paramsObj);

    return url + (url.indexOf('?') > -1 ? '&' : '?') + queryParams;
  }

  /**
   * We need to save off a session so when they come back we can pull it up.
   * @param paymentDetails The alternate payment details
   * @returns An observable to get a session id
   */
  private prepareForRedirect(paymentDetails: Partial<AltPayRedirectDetails>): void {
    const { parentConfig, language, merchantId, billingDemographics, delivery, redirectUrl } = this;
    const { tenantName } = this.clientConfiguration;
    const { paymentMethodCode } = paymentDetails;
    const newConfig = cloneDeep(parentConfig.config);
    const isAdyenProvider = this.paymentProvider === PaymentProviderType.Adyen;

    newConfig.user = {
      ...(newConfig.user ?? {}),
      firstName: returnOrDefaultString(billingDemographics.firstName),
      lastName: returnOrDefaultString(billingDemographics.lastName),
      middleInitial: returnOrDefaultString(billingDemographics.middleInitial),
      address1: returnOrDefaultString(billingDemographics.address1),
      address2: returnOrDefaultString(billingDemographics.address2),
      city: returnOrDefaultString(billingDemographics.city),
      stateProvince: returnOrDefaultString(billingDemographics.state),
      country: returnOrDefaultString(billingDemographics.country),
      zipPostal: returnOrDefaultString(billingDemographics.zip),
      email: returnOrDefaultString(billingDemographics.email),
      phone: returnOrDefaultString(billingDemographics.phone),
      phoneCountryCode: returnOrDefaultString(billingDemographics.telCode),
    };

    const sessionPayload: SessionPayload = { parentConfig: newConfig, giftCardState: this.giftCardState, redirectUrl };

    if (!isAdyenProvider) {
      // Session information will be used to retrieve redirect information
      sessionPayload.queryParams = objectToQueryParams({
        [AlternatePaymentQueryParams.SESSION_ID]: this.parentConfig.sessionId,
        [AlternatePaymentQueryParams.TENANT]: tenantName,
        [AlternatePaymentQueryParams.PAYMENT_MERCHANT_ID]: paymentDetails?.paymentMerchantId,
        [AlternatePaymentQueryParams.PAYMENT_TYPE]: paymentDetails?.paymentType,
        paymentMethodCode,
      });
    }

    /**
     * We need to ensure we add the address to the InitializeData model so that the delivery resolve
     * passes when they come back and don't get kicked to the /select route.
     */
    if (validString(delivery.selectedDeliveryMethod)) {
      const { address } = delivery;
      newConfig.delivery = newConfig.delivery ?? {};
      newConfig.delivery.defaultSelectedId = delivery.selectedDeliveryMethod;

      newConfig.delivery.address = {
        ...(newConfig.delivery.address ?? {}),
        firstName: returnOrDefaultString(address?.firstName),
        lastName: returnOrDefaultString(address?.lastName),
        middleInitial: returnOrDefaultString(address?.middleInitial),
        address1: returnOrDefaultString(address?.address1),
        address2: returnOrDefaultString(address?.address2),
        city: returnOrDefaultString(address?.city),
        stateProvince: returnOrDefaultString(address?.state),
        country: returnOrDefaultString(address?.country),
        zipPostal: returnOrDefaultString(address?.zip),
        email: returnOrDefaultString(address?.email),
        phone: returnOrDefaultString(address?.phone),
        phoneCountryCode: returnOrDefaultString(address?.telCode),
      };
    }

    const sessionRequest: CreateAccessoPaySessionRequest = {
      language,
      merchantId,
      tenantName,
      payload: JSON.stringify(sessionPayload),
      sessionState: SessionState.REDIRECT,
    };

    this.store.dispatch(prepareForAlternatePaymentRedirectAction({ payload: { sessionRequest, paymentDetails } }));
  }

  /**
   * Returns complete initiate alternate payment request based on the payment method code
   * @param req initiate alt pay request
   * @param encryptedToken encrypted adyen token
   * @param provider payment provider details
   * @returns Initiate alternate payment request
   */
  private getCompleteInitiateAltPayRequest(req: InitiateAltPayRequest, encryptedToken: string): InitiateAltPayRequest {
    const { AFTERPAY, BANCONTACT_DESKTOP, IDEAL, KAKAO_PAY, MB_WAY, SEPADD, SOFORT, KLARNA_PAY_NOW, KLARNA_PAY_OVER_TIME } = PaymentMethodCode;
    const { billingDemographics: demographics } = this;
    const { payment_type: paymentMethodCode } = req;

    if (this.paymentProvider === PaymentProviderType.Adyen) {
      req.billingAddress = buildBillinDemographics(demographics);
    }

    if (this.insurance.isProtected && !this.insurance.splitInsurance) {
      req = { ...req, CARDHOLDER: formatDemographicsAsUserDataToCardHolder(demographics) };
    }

    switch (paymentMethodCode) {
      case BANCONTACT_DESKTOP:
        if (this.paymentProvider === PaymentProviderType.Adyen) {
          return { ...req, ...this.buildInitialAltPayRequest(demographics, { token_response: encryptedToken }) };
        }
        return { ...req, ...this.buildInitialAltPayRequest(demographics) };
      case MB_WAY:
        return { ...req, ...this.buildInitialAltPayRequest({ ...demographics }) };
      case SEPADD:
        return { ...req, iban_number: demographics.iban_number, sepa_account_holder_name: demographics.account_holder_name };
      case AFTERPAY:
      case IDEAL:
      case SOFORT:
        return this.paymentProvider === PaymentProviderType.Cybersource ? { ...req, ...this.buildInitialAltPayRequest(demographics) } : req;
      case KAKAO_PAY:
        if (this.paymentProvider === PaymentProviderType.Adyen) {
          return req;
        }
        return { ...req, ...this.buildInitialAltPayRequest(demographics) };
      case KLARNA_PAY_NOW:
      case KLARNA_PAY_OVER_TIME:
        return { ...req, ...this.buildInitialAltPayRequest(demographics) };
      default:
        return req;
    }
  }

  /**
   * Build JSON with additional fields for the initial alternate payment request
   * @param demographics demographics
   * @param card credit card information
   * @returns object with additional info for Initiate alternate payment request
   */
  private buildInitialAltPayRequest(demographics: Partial<DemographicsFormData>, card = null) {
    const { screen, navigator, location } = this.getWindow();
    const address = validString(demographics.address1) ? `${demographics.address1} ${validString(demographics.address2) ? demographics.address2 : ''}` : '';

    const requestPayload = {
      CARDHOLDER: formatDemographicsAsUserDataToCardHolder(demographics),
      accept_header: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
      color_depth: screen.colorDepth,
      java_enabled: isFunction(navigator.javaEnabled) ? navigator.javaEnabled() : false,
      screen_height: screen.height,
      screen_width: screen.width,
      store_url: location.origin,
      timezone_offset: new Date().getTimezoneOffset(),
      user_agent: navigator.userAgent,
      shopper_ip: this.environmentInfo.ipAddress,
    };

    if (card) {
      return {
        ...requestPayload,
        CARD: card,
      };
    }

    return requestPayload;
  }

  /**
   * Sends the actual initiate alt pay call
   * @param accessoPaySessionId The session token for our saved data
   * @param paymentDetails The alternate payment details
   */
  sendInitiateAltPay(accessoPaySessionId: string, paymentDetails: Partial<AltPayRedirectDetails>) {
    const { paymentProvider } = this;
    const { paymentMethodCode, paymentMerchantId, paymentType, issuerId, encryptedToken } = paymentDetails;
    const { cartItems, fees } = getLineItemsAndFees(this.parentConfig);
    const { type } = this.environmentInfo.device;
    const win = this.getWindow();
    let totalAmount = this.amount;
    const { insurance, amount: amountAfterInsurance } = calculateSplitAmountWithInsurance(totalAmount, this.insurance);
    totalAmount = amountAfterInsurance;
    const { amount: totalSplitAmount, donation } = calculateSplitAmountWithDonation(totalAmount, this.donation);

    const initiateAltPayRequest: InitiateAltPayRequest = this.getCompleteInitiateAltPayRequest(
      {
        language: this.clientConfiguration.language,
        amount: totalSplitAmount,
        origin: win.origin,
        merchant_id: paymentMerchantId,
        payment_type: paymentMethodCode,
        return_url: this.getRedirectUrl(this.redirectUrl, accessoPaySessionId, paymentMethodCode),
        cartItems,
        fees,
        device_type: type,
        ...(insurance && { insurance }),
        ...(donation?.donation_amount > 0 && { donation }),
      },
      encryptedToken
    );

    if (validString(issuerId)) {
      initiateAltPayRequest.issuer_id = issuerId;
    }

    this.apiService
      .post<InitiateAltPayResponse>(ApiRequestType.INITIATE_ALT_PAY, initiateAltPayRequest)
      .pipe(take(1))
      .subscribe(({ status, response }) => {
        if (status === ApiStatus.OK) {
          this.initiateAltPayResponse = response;

          if (!validString(response.redirect_url)) {
            const actions = [];
            const isAdyenWebCompRequired = response.action && isAdyenWebComponentRequired(paymentProvider, paymentMethodCode);

            if (!isAdyenWebCompRequired && isPendingWithoutRedirect(paymentMethodCode)) {
              const isCustomQrCodeRequired = hasValidQrData(response) && isCustomQrComponentRequired(paymentMethodCode);
              const buildPaymentCompletePayloadParams: buildPaymentCompletePayloadParams = {
                InitAltPayResponse: response,
                paymentMethodCode,
                paymentType: paymentType as PaymentType,
                paymentMerchantId,
                paymentProvider,
                amount: initiateAltPayRequest.amount,
                donation: initiateAltPayRequest.donation ? convertDonationResponse(initiateAltPayRequest.donation) : null,
                insurance: initiateAltPayRequest.insurance
                  ? convertInsuranceResponse({ ...initiateAltPayRequest.insurance, insurance_amount: initiateAltPayRequest.insurance.premium_amount })
                  : null,
              };
              if (isCustomQrCodeRequired) {
                // For now, TMBThanachart Bank is the only payment type that requires a custom QR rendering
                actions.push(
                  initiateTmbThanachartAction({
                    payload: {
                      authId: response.auth_id,
                      paymentMethodCode,
                      paymentMerchantId,
                      paymentProvider,
                      qrData: response.qr_code_data,
                    },
                  })
                );
              } else {
                // Assume pending authorization
                actions.push(sendPaymentComplete(this.buildPaymentCompletePayload(buildPaymentCompletePayloadParams)));
              }
            } else {
              if (isAdyenWebCompRequired) {
                actions.push(routeTo({ payload: ['select', 'alternatepayments', 'adyen-web-ui', paymentType] }));
              } else {
                actions.push(routeTo({ payload: ['select', 'alternatepayments', paymentType] }));
              }
            }
            actions.forEach((action) => this.store.dispatch(action));
          } else {
            let totalAmount = this.amount;
            const { insurance, amount: amountAfterInsurance } = calculateSplitAmountWithInsurance(totalAmount, this.insurance);
            totalAmount = amountAfterInsurance;
            const { amount: totalSplitAmount, donation } = calculateSplitAmountWithDonation(totalAmount, this.donation);
            totalAmount = totalSplitAmount;
            this.store.dispatch(
              initiateAlternatePaymentRedirectAction({
                amount: totalAmount,
                authId: response.auth_id,
                method: response.http_method,
                paymentReference: response.paymentReference,
                url: response.redirect_url,
                paymentMethodCode,
                redirectParams: {
                  MD: response.md,
                  PaReq: response.pa_req,
                  TermUrl: response.term_url,
                },
                ...(donation && { donation }),
                ...(insurance && { insurance }),
              })
            );
          }
        } else {
          const { localeService } = this;
          const { error_code, error_msg } = response;
          const initiator = 'Adyen Resolve: payment initiation error';
          const onClose = () => this.store.dispatch(routeTo({ payload: ['select'] }));
          const notificationPayload: DialogData = {
            buttonLabel: localeService.get('common.close'),
            dialogType: NotificationDialogType.GENERAL,
            initiator,
            message: '',
            onClose,
          };
          if (validString(error_code)) {
            notificationPayload.message = localeService.get(`errorcode.${error_code}`);
          } else if (validString(error_msg)) {
            notificationPayload.message = error_msg;
          } else {
            notificationPayload.message = localeService.get(`alternatepayment.internalerror`);
          }
          [
            sendMessageAction({
              key: EVENT_PAYMENT_FAILED,
              payload: {
                errorCode: error_code,
                details: error_msg,
                title: '',
              } as PaymentFailed,
            }),
            hidePageSpinner({ initiator }),
            showNotificationAction(notificationPayload),
          ].forEach((action) => this.store.dispatch(action));
        }
      });
  }

  /**
   * build the payload for the sendPaymentComplete action
   * InitAltPayResponse does not return donation property
   * @param object properties to build payment complete event
   * @returns the payload for complete order event
   */
  buildPaymentCompletePayload({
    InitAltPayResponse,
    paymentMethodCode,
    paymentType,
    paymentMerchantId,
    paymentProvider,
    amount,
    donation = null,
    insurance = null,
  }: buildPaymentCompletePayloadParams): {
    payload: PaymentComplete | SplitPaymentComplete;
  } {
    const { isGiftCardOnlyPayment, totalBalance } = this.giftCardState;
    if (!isGiftCardOnlyPayment && totalBalance > 0) {
      const paymentInfo = [];
      paymentInfo.push({
        amount,
        authId: InitAltPayResponse.paymentReference,
        method: paymentType,
        approval_code: InitAltPayResponse.result_code,
        awaitingAuthorization: isPendingWithoutRedirect(paymentMethodCode),
        legacy: {
          authId: InitAltPayResponse.auth_id.toString(),
          paymentMerchantId: paymentMerchantId,
          paymentMethodCode: InitAltPayResponse.payment_type,
          rawCardBrand: InitAltPayResponse.payment_type,
          paymentProviderName: paymentProvider,
        },
        billing: buildBillingPayload(this.billingDemographics),
        ...(this.delivery?.deliveryMethodsConfigured &&
          this.delivery.address && {
            delivery: buildDeliveryPayload(this.delivery.address),
          }),
        ...(donation && { donation }),
        ...(insurance && { insurance }),
      });
      this.giftCardState.giftcards.forEach((giftcard) => {
        const { authId: giftCardAuthId, preAuthBalance, paymentReference, donation } = giftcard;
        paymentInfo.push({
          amount: donation ? toDecimal(preAuthBalance - donation.donationAmount, 2) : preAuthBalance,
          method: PaymentType.GIFT_CARD,
          authId: paymentReference,
          legacy: {
            authId: giftCardAuthId,
            paymentMerchantId: this.giftCardConfiguration.paymentMerchantId,
            paymentMethodCode: this.giftCardConfiguration.paymentMethodCode,
            paymentProviderName: this.giftCardConfiguration.paymentProviderName,
            rawCardBrand: this.giftCardConfiguration.rawCardBrand,
          },
          user: {
            billing: buildBillingPayload(this.billingDemographics),
          },
          ...(donation && { donation }),
        });
      });
      const paymentCompletePayload: SplitPaymentComplete = {
        paymentInfo,
      };
      return { payload: paymentCompletePayload };
    } else {
      return {
        payload: {
          amount,
          authId: InitAltPayResponse.paymentReference,
          method: paymentType,
          approval_code: InitAltPayResponse.result_code,
          awaitingAuthorization: isPendingWithoutRedirect(paymentMethodCode),
          legacy: {
            authId: InitAltPayResponse.auth_id.toString(),
            paymentMerchantId: paymentMerchantId,
            paymentMethodCode: InitAltPayResponse.payment_type,
            rawCardBrand: InitAltPayResponse.payment_type,
            paymentProviderName: paymentProvider,
          },
          user: {
            billing: buildBillingPayload(this.billingDemographics),
            ...(this.delivery?.deliveryMethodsConfigured &&
              this.delivery.address && {
                delivery: buildDeliveryPayload(this.delivery.address),
              }),
          },
          ...(donation && { donation }),
          ...(insurance && { insurance }),
        },
      };
    }
  }
}
