import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, createSelector } from '@ngrx/store';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { sendPaymentComplete } from 'src/app/core/analytics/analytics.actions';
import { sendMessageAction } from 'src/app/core/application-bridge/application-bridge.actions';
import {
  AuthorizedPaymentInfo,
  PaymentComplete,
  PaymentInfo,
  PaymentType,
  SplitPaymentComplete,
} from 'src/app/core/application-bridge/application-bridge.models';
import { hidePageSpinner, showPageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { User } from 'src/app/core/parent-config/parent-config.state';
import { selectGiftCard } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { routeToWithOptions } from 'src/app/core/routing/routing.actions';
import { setAuthorizingAction } from 'src/app/core/session/session.actions';
import { TemporaryLoggerService } from 'src/app/core/temporary-logger/temporary-logger.service';
import { EVENT_BILLING_DEMOGRAPHICS } from 'src/app/shared/enums/application-bridge.enums';
import { selectTotalAmount } from 'src/app/shared/selectors/configuration.selectors';
import { toDecimal } from 'src/app/shared/utilities/number.utils';
import { returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { validArray } from 'src/app/shared/utilities/types.utils';
import { getQueryParamsFromUrl } from 'src/app/shared/utilities/url.utils';
import { Cardinal3DSService } from '../cybersource/credit-card/cardinal-3ds.service';
import { selectGiftCardState } from '../gift-card/gift-card.selectors';
import { hasPreAuthGiftCards } from '../gift-card/gift-card.utils';
import { buildBillingPayload, buildDeliveryPayload } from './alternate-payments/alternate-payments.utils';
import {
  finalizeFullPaymentDetailsAction,
  gatherPaymentDetailsAdyen3DSAction,
  gatherPaymentDetailsForAdyenWebComponentAction,
  gatherPaymentDetailsForAmazonPayAction,
  gatherPaymentDetailsForApplePayAction,
  gatherPaymentDetailsForCreditCardAction,
  gatherPaymentDetailsForCybersource3DSAction,
  gatherPaymentDetailsForGiftCardOnlyPaymentAction,
  gatherPaymentDetailsForGooglePayAction,
  gatherPaymentDetailsForPayPalAction,
  gatherPaymentDetailsForRedirectPaymentAction,
  gatherPaymentDetailsForStoredWalletAction,
  gatherPaymentDetailsForUpliftPaymentAction,
  giftCardSplitPaymentWithCybersource3DSSuccessAction,
  initializeGiftCardSplitPaymentWithCybersource3DSAction,
  initiateCybersource3DSTransactionAction,
  routeToRedirectResultAction,
  updateAlternatePaymentDemographicsAction,
  updateBillingDemographicsAction,
} from './billing.actions';
import { selectBillingDetailsForPayment } from './billing.selectors';
import { initiateCybersourceGooglePay3DSTransactionAction } from './google-pay/google-pay.actions';
import { selectPaymentMethods } from '../payment/payment.selectors';
import { GiftCardState } from '../gift-card/gift-card.state';
import { GiftCardPayment } from 'src/app/core/payment-configuration/payment-configuration.model';
import { BillingState } from './billing.state';
import { DeliveryState } from '../delivery/delivery.state';
import { ApiService } from 'src/app/core/api/api.service';
import { PaymentAuthResponse } from 'src/app/core/api/endpoints/payment-auth';
import { ApiStatus } from 'src/app/core/api/api.interface';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { getErrorLocaleFromResponse } from 'src/app/core/notification/notification.utils';
import { LocaleService } from 'src/app/core/locale/locale.service';

const paymentInfoSelector = createSelector(
  selectGiftCardState,
  selectTotalAmount,
  selectGiftCard,
  selectBillingDetailsForPayment,
  selectPaymentMethods,
  (giftCardState, totalAmount, giftCardConfiguration, billingDetails, payments) => {
    return {
      giftCardState,
      totalAmount,
      giftCardConfiguration,
      billingDetails,
      payments,
    };
  }
);

@Injectable({
  providedIn: 'root',
})
export class BillingEffects {
  /**
   * The constructor
   * @param actions$ Our action observable
   * @param store The store
   * @param adyen3DSService Adyen 3ds service
   * @param localeService The locale service
   * @param window Window
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    private actions$: Actions,
    private store: Store<AppState>,
    private cardinalService: Cardinal3DSService,
    private tempLogger: TemporaryLoggerService,
    private apiService: ApiService,
    private localeService: LocaleService
  ) {}

  /**
   * This effect is triggered when the user clicks on Pay
   * and the card is enrolled for 3DS
   */
  initializeCybersource3ds$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initiateCybersource3DSTransactionAction, initiateCybersourceGooglePay3DSTransactionAction),
        withLatestFrom(
          this.store.select(createSelector(selectGiftCardState, selectTotalAmount, (giftCardState, totalAmount) => ({ giftCardState, totalAmount })))
        ),
        tap(() => this.store.dispatch(setAuthorizingAction({ authorizing: true }))),
        map(([action, currStates]) => {
          if (currStates.giftCardState.totalBalance > 0 && !hasPreAuthGiftCards(currStates.giftCardState.giftcards)) {
            this.store.dispatch(initializeGiftCardSplitPaymentWithCybersource3DSAction({ amount: currStates.totalAmount, payload: action.payload }));
          } else {
            this.cardinalService.initiateCardinal3DS(action.payload);
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Completes split payment
   */
  completeSplitPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(giftCardSplitPaymentWithCybersource3DSSuccessAction),
        map((action) => this.cardinalService.initiateCardinal3DS(action.payload))
      ),
    { dispatch: false }
  );

  /**
   * This method is called whenever you update the billing demographics so that the parent application
   * knows about the information.
   */
  updateDemographics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAlternatePaymentDemographicsAction, updateBillingDemographicsAction),
      map(({ payload }) => {
        return sendMessageAction({
          key: EVENT_BILLING_DEMOGRAPHICS,
          payload: {
            firstName: returnOrDefaultString(payload?.firstName),
            lastName: returnOrDefaultString(payload?.lastName),
            middleInitial: returnOrDefaultString(payload?.middleInitial),
            email: returnOrDefaultString(payload?.email),
            phone: returnOrDefaultString(payload?.phone),
            phoneCountryCode: returnOrDefaultString(payload?.telCode),
            phoneRegionCode: returnOrDefaultString(payload?.regionCode),
            address1: returnOrDefaultString(payload?.address1),
            address2: returnOrDefaultString(payload?.address2),
            city: returnOrDefaultString(payload?.city),
            stateProvince: returnOrDefaultString(payload?.state),
            country: returnOrDefaultString(payload?.country),
            zipPostal: returnOrDefaultString(payload?.zip),
          } as User,
        });
      })
    )
  );

  /**
   * This effect is triggered when the user is redirected back after completing payment
   */
  routeToRedirectResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routeToRedirectResultAction),
      map(() =>
        routeToWithOptions({
          routeParams: ['redirect-result'],
          options: { queryParams: getQueryParamsFromUrl(this.window) },
        })
      )
    )
  );

  /**
   * This is triggered when the payment has be authorized and we are ready to check
   * for partial payments or finalize payment details
   */
  gatherPaymentDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        gatherPaymentDetailsForStoredWalletAction,
        gatherPaymentDetailsForCreditCardAction,
        gatherPaymentDetailsForCybersource3DSAction,
        gatherPaymentDetailsAdyen3DSAction,
        gatherPaymentDetailsForAmazonPayAction,
        gatherPaymentDetailsForGooglePayAction,
        gatherPaymentDetailsForApplePayAction,
        gatherPaymentDetailsForPayPalAction,
        gatherPaymentDetailsForRedirectPaymentAction,
        gatherPaymentDetailsForUpliftPaymentAction,
        gatherPaymentDetailsForAdyenWebComponentAction
      ),
      withLatestFrom(this.store.select(paymentInfoSelector)),
      mergeMap(([action, { giftCardState, totalAmount, giftCardConfiguration, billingDetails, payments }]) => {
        if (payments.length > 0) {
          return this.apiService.paymentAuth().pipe(
            mergeMap((apiResponse) => {
              if (apiResponse.status === ApiStatus.FAILED) {
                return [
                  setAuthorizingAction({ authorizing: false }),
                  showNotificationAction({
                    buttonLabel: 'close',
                    dialogType: NotificationDialogType.GENERAL,
                    initiator: 'payment auth failure',
                    message: getErrorLocaleFromResponse(this.localeService, apiResponse),
                  }),
                ];
              }

              return this.processPaymentFlow({
                payload: action.payload,
                giftCardState,
                totalAmount,
                giftCardConfiguration,
                billingDetails,
                additionalPayments: apiResponse.response,
              });
            })
          );
        }

        return this.processPaymentFlow({
          payload: action.payload,
          giftCardState,
          totalAmount,
          giftCardConfiguration,
          billingDetails,
        });
      })
    )
  );

  private processPaymentFlow({
    payload,
    giftCardState,
    totalAmount,
    giftCardConfiguration,
    billingDetails,
    additionalPayments,
  }: {
    payload: AuthorizedPaymentInfo;
    giftCardState: GiftCardState;
    totalAmount: number;
    giftCardConfiguration: GiftCardPayment;
    billingDetails: {
      billingState: BillingState;
      deliveryState: DeliveryState;
    };
    additionalPayments?: PaymentAuthResponse;
  }) {
    const { amount } = payload;
    const { isGiftCardOnlyPayment, totalBalance } = giftCardState;

    if (!isGiftCardOnlyPayment && totalBalance > 0) {
      const splitAmount = totalAmount > totalBalance ? toDecimal(totalAmount - totalBalance, 2) : 0;
      const amountPaid = amount ?? splitAmount;
      const {
        method,
        approval_code,
        authId,
        legacy,
        lastFour,
        cardType,
        maskedPan,
        awaitingAuthorization,
        donation,
        insurance: complementaryInsurance,
      } = payload;

      const { billingState, deliveryState } = billingDetails;
      const { firstName, lastName, email, phone, telCode } = billingState.demographics ?? {};

      const paymentInfo: PaymentInfo[] = [
        {
          amount: amountPaid,
          awaitingAuthorization,
          method,
          approval_code,
          authId,
          legacy,
          lastFour,
          cardType,
          maskedPan,
          billing: buildBillingPayload(billingState.demographics ?? {}),
          ...(donation && { donation }),
          ...(complementaryInsurance && { insurance: complementaryInsurance }),
        },
      ];

      if (additionalPayments) {
        additionalPayments.payments.forEach((payment) => {
          paymentInfo.push({
            amount: payment.amount,
            method: PaymentType.TESCO_VOUCHER, // !! Need to get this from the payment response
            authId: payment.authId,
            legacy: {
              authId: payment.authId,
              paymentMerchantId: giftCardConfiguration.paymentMerchantId,
              paymentMethodCode: payment.paymentMethodCode,
              paymentProviderName: payment.provider,
            },
            billing: {},
          });
        });
      }

      giftCardState.giftcards.forEach((giftcard) => {
        const { authId: giftCardAuthId, amount, paymentReference, donation: giftCardDonation, insurance: giftCardInsurance } = giftcard;

        paymentInfo.push({
          amount,
          method: PaymentType.GIFT_CARD,
          authId: paymentReference,
          legacy: {
            authId: giftCardAuthId,
            paymentMerchantId: giftCardConfiguration.paymentMerchantId,
            paymentMethodCode: giftCardConfiguration.paymentMethodCode,
            paymentProviderName: giftCardConfiguration.paymentProviderName,
            rawCardBrand: giftCardConfiguration.rawCardBrand,
          },
          billing: {
            firstName,
            lastName,
            email,
            phone,
            phoneCountryCode: telCode,
          },
          ...(giftCardDonation && { donation: giftCardDonation }),
          ...(giftCardInsurance && { insurance: giftCardInsurance }),
        });
      });

      const paymentCompletePayload: SplitPaymentComplete = {
        paymentInfo,
      };

      if (deliveryState.deliveryMethodsConfigured && deliveryState.collectAddress) {
        paymentCompletePayload.delivery = buildDeliveryPayload(deliveryState.address ?? {});
      }
      this.tempLogger.log('billing-effects', 'gather payment details complete - send payment');
      return [showPageSpinner({ initiator: 'Send Payment Details to Analytics' }), sendPaymentComplete({ payload: paymentCompletePayload })];
    }

    this.tempLogger.log('billing-effects', 'gather payment details complete - finalize payment');
    return [hidePageSpinner({ initiator: 'Gather Payment Complete Acknowledged' }), finalizeFullPaymentDetailsAction({ payload: payload })];
  }

  /**
   * This is triggered when the payment details are finalized (for non partial payments) and we are ready to send
   * payment complete event
   */
  gatherFullAmountPaymentDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(finalizeFullPaymentDetailsAction),
      withLatestFrom(this.store.select(selectBillingDetailsForPayment)),
      mergeMap(([action, stateFromStore]) => {
        const { method, approval_code, authId, legacy, lastFour, cardType, maskedPan, awaitingAuthorization, donation, insurance, amount } = action.payload;
        const { billingState, deliveryState } = stateFromStore;
        const paymentCompletePayload: PaymentComplete = {
          method,
          approval_code,
          authId,
          legacy,
          lastFour,
          cardType,
          maskedPan,
          awaitingAuthorization,
          amount,
          donation,
          insurance,
          user: {
            billing: buildBillingPayload(billingState.demographics ?? {}),
          },
        };

        if (deliveryState.deliveryMethodsConfigured && deliveryState.collectAddress) {
          paymentCompletePayload.user.delivery = buildDeliveryPayload(deliveryState.address ?? {});
        }

        this.tempLogger.log('billing-effects', 'finalize complete - send payment');
        return [showPageSpinner({ initiator: 'Send Payment Details to Analytics' }), sendPaymentComplete({ payload: paymentCompletePayload })];
      })
    )
  );

  /**
   * This is triggered when the payment has been made only though giftcard/s
   */
  gatherGiftCardOnlyPaymentDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gatherPaymentDetailsForGiftCardOnlyPaymentAction),
      withLatestFrom(this.store.select(selectBillingDetailsForPayment)),
      map(([action, stateFromStore]) => {
        const { billingState, deliveryState } = stateFromStore;
        const paymentInfo = [];
        if (validArray(action.payload) && action.payload.length === 1) {
          return finalizeFullPaymentDetailsAction({ payload: action.payload[0] });
        }
        action.payload.forEach((payload) => {
          const { method, authId, legacy, amount } = payload;
          paymentInfo.push({
            amount,
            method,
            authId,
            legacy,
            billing: buildBillingPayload(billingState.demographics ?? {}),
            ...(payload.donation && { donation: payload.donation }),
            ...(payload.insurance && { insurance: payload.insurance }),
          });
        });

        const paymentCompletePayload: SplitPaymentComplete = {
          paymentInfo,
        };

        if (deliveryState.deliveryMethodsConfigured && deliveryState.collectAddress) {
          paymentCompletePayload.delivery = buildDeliveryPayload(deliveryState.address ?? {});
        }

        return sendPaymentComplete({ payload: paymentCompletePayload });
      })
    )
  );
}
